
export const getLoggedUser = () => {
    try {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user !== undefined && user !== null) {
            return user

        }
    } catch (error) {
        //   console.log('error getting user', error)
    }
}
export const saveUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
}

export const saveUserToken = (token) => {
    localStorage.setItem('token', token);
}
export const removeFromLocalStorage = (key) => {
    localStorage.removeItem(key);
}

export const getLocationDetails = (data = {}, prediction = null) => {

    const addressComponents = [...data]
    let location = {}

    if (prediction) {//for auto complete result
        const lastIndex = addressComponents.length - 1
        const DEFAULT_COUNTRY = "India"
        if (addressComponents[addressComponents.length - 1]?.value.toLowerCase() === "india") {

            location.place = addressComponents[0]?.value || ""
            location.country = addressComponents[lastIndex]?.value || ""
            location.state = addressComponents[lastIndex - 1]?.value || ""
            location.city = addressComponents[lastIndex - 2]?.value || ""
            location.pinCode = null

        } else {
            location.place = addressComponents[0]?.value || ""
            location.country = DEFAULT_COUNTRY
            location.state = addressComponents[lastIndex]?.value || ""
            location.city = addressComponents[lastIndex - 1]?.value || ""
            location.pinCode = null
        }
        // const [place, city, state, country] = addressComponents
        // console.log(addressComponents[addressComponents.length - 1]?.value.toLowerCase() === "india")

    } else {
        // const addressComponents = res?.results[0]?.address_components;
        location.place = addressComponents?.find((comp) =>
        comp?.types?.includes('route') || comp?.types?.includes('neighborhood"') || comp?.types?.includes('sublocality') || comp?.types?.includes('sublocality_level_1') || comp?.types?.includes('route')
        )?.long_name;

        location.city = addressComponents?.find((comp) =>
            comp?.types?.includes('locality') || comp?.types?.includes('administrative_area_level_3')
        )?.long_name;
        location.pinCode = addressComponents?.find((comp) =>
            comp?.types?.includes('postal_code')
        )?.long_name;

        location.state = addressComponents?.find((comp) =>
            comp?.types?.includes('administrative_area_level_1')
        )?.long_name;
        location.country = addressComponents?.find((comp) =>
            comp?.types?.includes('country')
        )?.long_name;

    }


    return location

}


export function isValidURL(url) {
    // Regular expression pattern to match a URL
    var pattern = new RegExp('^(https?:\\/\\/)?' + // Protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // Domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // Port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // Query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // Fragment locator

    return pattern?.test(url);
}

export function handleErrors(error) {
    if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        // console.error('Response Error:', error.response.data);
        // alert(error.response.data.message)
        throw error.response.data;
    } else if (error.request) {
        // The request was made but no response was received
        //console.error('Request Error:', error.request);
        // alert("No response received from the server.")

        throw { message: 'No response received from the server.' };
    } else {
        // Something happened in setting up the request that triggered an error
        // console.error('Error:', error.message);
        // alert("Some error occured.")
        throw { message: 'An error occurred while making the request.' };
    }
}

export function isYouTubeVideo(url) {
    // Regular expressions for YouTube video URLs
    if (!!url) {
        const youtubeVideoRegex = [
            /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/,
            /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]+)/,
        ];

        // Check if the URL matches any of the YouTube video URL formats
        return youtubeVideoRegex.some((regex) => regex.test(url));
    }

}

export function isImageUrl(url) {
    // Extract the path portion of the URL
    const urlParts = url.split('?');
    const urlWithoutQuery = urlParts[0];
    
    // Get the file extension from the path
    const pathParts = urlWithoutQuery.split('.');
    const extension = pathParts[pathParts.length - 1].toLowerCase();
    
    // Define an array of image file extensions
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
    
    // Check if the extension is in the array of image file extensions
    return imageExtensions.includes(extension);
}



export const phoneRegex = /^\d{10}$/; // Matches 10 digits
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email pattern