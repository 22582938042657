import React from 'react';
import './styles.css'
import { customRequest } from '../../functions/request';
import { Link, useNavigate } from 'react-router-dom';
import LanguageAndMoodModal from '../../components/moodsAndLanguageModal';
import { useDispatch, useSelector } from "react-redux";
import { setAddInPosts, setPostsInBulk, setSelectedMood, setShowAlertPopup } from '../../redux/dataSlice';
import { MdLocationPin, MdMyLocation } from 'react-icons/md';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import Spinner from "react-spinkit";
import Brand from '../../components/Brand';
import { getLoggedUser } from '../../functions/helper';
import { Colors } from '../../constants/colors';
import Hand from '../../assets/hand.svg';
import Today from './today';
import Posts from '../../components/posts';
import MoodAvatar from '../../components/moodAvatar';



let postOffset = 0

export default function Community() {
   const user = getLoggedUser()
   const [activeTab, setActiveTab] = React.useState('community');
   const { allMoods, viewMoods, selectedMood, selectedLanaguageId, posts: slicePosts } = useSelector((state) => state.data);
   const [isBottomLoading, setIsBottomLoading] = React.useState(false);
   const [allPostLoaded, setAllPostLoaded] = React.useState(false);
   const dispatch = useDispatch();
   const [isLoading, setIsLoading] = React.useState(false);
   const [viewTodaySection, setViewTodaySection] = React.useState(false)
   const [moodBadges, setMoodBadges] = React.useState(null);
   const navItems = [
      { id: 'today', label: 'Today', onClick: () => setViewTodaySection(true) },
      { id: 'live', label: 'LIVE', onClick: onCLickLive },
      { id: 'community', label: 'Community', onClick: () => setViewTodaySection(false) },
      { id: 'sadhana', label: 'Sadhana', onClick: () => handleRedirect('/contestlist') },
      // { id: 'newPost', label: 'New Post', onClick: () => handleRedirect('/newpost') },
      { id: 'profile', label: 'Profile', onClick: () => handleRedirect('/myprofile') },
   ];
   //Location
   const currentLocation = useSelector(state => state.data.currentLocation);

   const navigate = useNavigate();

   const getPosts = (addPosts) => {
      let data = {
         language: selectedLanaguageId,
      };

      if (addPosts) {
         postOffset = postOffset + 10
         data.offset = postOffset;
      }

      if (selectedMood !== "All") {
         data.mood = selectedMood;
      } else if (viewMoods?.length > 0) {
         data.mood = viewMoods;
      }

      if (currentLocation !== "Everywhere") {
         if (currentLocation === "Nearme") {
            data.location = 'Nearme'
            data.locationCoords = JSON.parse(localStorage.getItem('location_coords'));
         } else {
            data.location = currentLocation;
            data.locationCoords = JSON.parse(localStorage.getItem('location_coords'));

         }
      }


      if (addPosts && !allPostLoaded) {
         setIsBottomLoading(true);
         customRequest('post/allposts', data).then((res) => {
            //  console.log('new post====>', res.posts.length,typeof res.posts.length)
            setIsBottomLoading(false);
            if (res.posts.length === 0) {
               setAllPostLoaded(true);
            }
            dispatch(setAddInPosts(res.posts));

         }).catch(e => {
         }).finally((() => {
            setIsBottomLoading(false);
            setIsLoading(false)
         }
         )
         )
      } else if (postOffset === 0) {
         setIsLoading(true)
         customRequest('post/allposts', data).then((res) => {
            dispatch(setPostsInBulk(res.posts));
         }).catch(e => {
            dispatch(
               setShowAlertPopup(
                  {
                     show: true,
                     title: "Alert",
                     message: "Some error occurred.",
                  }
               )
            )
         }).finally((() => {
            setIsBottomLoading(false);
            setIsLoading(false)
         }
         )
         )
      }

   }


   const handleSelectHomeMood = (mood) => {
      dispatch(setSelectedMood(mood.id));
      //  dispatch(setPostsInBulk('loading'));
   }

   const getMoodBadges = () => {
      setMoodBadges(
         allMoods?.map((mood) => {
            if (viewMoods?.includes(mood.id)) {
               return (
                  <a key={mood.id} className={selectedMood == mood.id ? "Tabsnavactive" : ""} onClick={() => { handleSelectHomeMood(mood) }} style={{ cursor: "pointer" }}>
                     <MoodAvatar image={mood.thumbnail_image} />
                     {mood.mood_name}
                  </a>
               )
            }
         })
      )
   }

   const handleRedirect = (route) => {
      if (localStorage.getItem('token')) {
         navigate(route)
      } else if (route === "/contestlist") {
         navigate(route)
      } else {
         let message = "Login to create new post.";
         if (route === "/myprofile") message = "Login to see your profile.";

         dispatch(
            setShowAlertPopup(
               {
                  show: true,
                  title: "Alert",
                  message: message,
                  showLoginBtn: true
               }
            )
         )
      }
   }

   React.useEffect(() => {
      //  console.log('getMoodBadges effect')
      getMoodBadges();

      // console.log("allmoods",allMoods)
      // console.log("viewMoods",viewMoods)
      //  console.log("selectedLanaguageId",selectedLanaguageId)


   }, [allMoods, viewMoods, selectedMood, selectedLanaguageId])



   const handleGetMorePostOnBottom = () => {
      if (!allPostLoaded) {
         setIsBottomLoading(true);
         getPosts(true);
      }
   }


   React.useEffect(() => {
      //  console.log('get posts effect')
      postOffset = 0
      setAllPostLoaded(false)
      getPosts();
   }, [selectedMood, currentLocation, selectedLanaguageId])

   function onCLickLive() {
      navigate('/live-darshan')
   }
  

   return (
      <BottomScrollListener onBottom={handleGetMorePostOnBottom} >
         <div className="main_warp">
            <nav className="navbar sticky-top navbar-light mob-header-bar">
               <div className="w-100" style={{ display: "flex", alignItems: "center" }}>
                  <Link className="navbar-brand header-text me-auto" to="/home">
                     <Brand />
                  </Link>
                  {viewTodaySection ?
                     <div style={{ padding: "7px 10px", marginLeft: "auto", marginRight: 10, color: Colors.purple, display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", }}>
                           <p className="m-0" style={{ fontSize: "1rem", fontWeight: "600" }}>Namaste</p>
                           <img src={Hand} style={{ marginLeft: 5, height: "1.5rem" }} />
                        </div>
                        <p className="m-0" style={{ fontSize: "1rem", fontWeight: "600" }}>{user?.full_name || ""}</p>

                     </div>
                     :
                     <>
                        <button
                           data-bs-toggle="modal"
                           data-bs-target="#languageModal"
                           type="button"
                           className="btn btn-secondary-light search-hide" style={{ padding: "7px 10px", marginLeft: "auto", marginRight: 10, backgroundColor: 'orangered', color: "#ffffff" }}>
                           {
                              currentLocation === "Nearme"
                                 ?
                                 <MdMyLocation size={15} style={{ marginRight: 4, marginBottom: 2 }} />
                                 :
                                 currentLocation === "Everywhere"
                                    ?
                                    <i className="fas fa-globe fa-lg me-1" style={{ textTransform: "capitalize", marginRight: 5 }}></i>
                                    :
                                    <MdLocationPin size={15} />
                           }
                           {currentLocation?.length >= 20 ? currentLocation?.split(" ")[0] : currentLocation}
                        </button>
                        <div className="bd-highlight col-1 px-0 align-self-center pe-1">
                           <div className="tabs-btn" data-bs-toggle="modal"
                              data-bs-target="#languageModal" >
                              <img alt='localbol' src="assets/images/NavIcons.svg" />
                           </div>
                        </div>
                     </>
                  }
               </div>
               <div className="Subnav">
                  <div className="d-flex" style={{ overflow: "scroll", width: "100%" }}>
                     {navItems.map((item) => (
                        <div key={item.id} className={`mx-2 mt-1 ${activeTab === item.id && 'nav-tab'}`} >
                           <p
                              id={`${item.id}-tab`}
                              type="button"
                              role="tab"
                              aria-controls={item.id}
                              aria-selected={activeTab === item.id}
                              onClick={() => {
                                 item.onClick();
                                 item.id === "newPost" ? setActiveTab(activeTab) : setActiveTab(item.id);
                              }}
                           >
                              {item.label}
                           </p>
                        </div>
                     ))}
                  </div>
               </div>
            </nav>
            {viewTodaySection ? <Today onShowCommunity={() => { setActiveTab('community'); setViewTodaySection(false) }} /> :
               <>
                  <div className="container px-0" >
                     <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                           <div className="homeTabs ">
                              <div className="row">
                                 <div className="Tabsnav" style={{ maxWidth: 767, marginLeft: 15, paddingRight: "16px" }}>
                                    {/* <a className={"active-live-feed"} onClick={onCLickLive} style={{ cursor: "pointer", color: Colors.white }}>
                                       <div className='white-dot' />
                                       LIVE
                                    </a> */}

                                    <a className={selectedMood === "All" ? "Tabsnavactive" : ""} onClick={() => { dispatch(setSelectedMood('All')) }} style={{ cursor: "pointer" }}>All</a>
                                    {
                                       moodBadges
                                    }
                                 </div>
                              </div>
                           </div>
                           <div className="w-100" >
                              <Posts loading={isLoading} post={slicePosts} />
                              {/* {posts} */}
                              {
                                 isBottomLoading
                                    ?
                                    <div style={{ width: "100%", padding: "16px 0", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                       <Spinner name='wave' />
                                    </div>
                                    :
                                    null
                              }
                           </div>
                        </div>
                     </div>
                  </div>
                  <LanguageAndMoodModal />
               </>
            }
            {/* floating btn */}
            <button onClick={() => handleRedirect('/newpost')} className='shadow-lg floating-btn'>
               <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
         </div>

      </BottomScrollListener>
   );
}

