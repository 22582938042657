import React from "react";

const MoodAvatar = ({ image }) => {
  return (
    <>
      {!!image ? (
        <img loading="lazy" className={"mood-avatar"} src={image} />
      ) : (
        <img
          loading="lazy"
          className={"mood-avatar"}
          src={`${process.env.PUBLIC_URL}/profile_image.png`}
        />
      )}
    </>
  );
};

export default MoodAvatar;
