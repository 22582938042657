import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import apiPath from '../../../../constants/apiPath'
import { emailRegex, phoneRegex } from '../../../../functions/helper';
import { customRequest } from '../../../../functions/request';
import { setShowAlertPopup } from '../../../../redux/dataSlice';


const useCreateAccount = () => {
    const [languages, setLanguages] = useState(null);
    const [loading, setLoading] = useState(false)
    const [isAgreeWithTerms, setIsAgreeWithTerms] = React.useState(true);
    const navigate=useNavigate()

    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        fullname: "",
        email: "",
        phone: "",
        username: "",
        password: "",
        gender: "",
        language_id: "",
        referral_code:""
    });

    const handleInput = (e) => {

        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleSelectLanguage = (param) => {
        console.log(param)
        setFormData((prev) => ({ ...prev, language_id: param }))
    }
    const handleSelectGender = (param) => {
        setFormData((prev) => ({ ...prev, gender: param }))
        // setSelectedLanguageId(e.target.value)
    }

    const getLanguages = () => {
        customRequest('auth/subdomain-languages').then((res) => {
            // console.log(res?.languages)
            // setFormData((prev)=>);
            setFormData((prev) => ({ ...prev, language_id: res?.languages[0]?.id }))
            setLanguages(
                res.languages
            )
        }).catch(e => console.log('error in language', e.message))
    }

    const handleCreateAccount = () => {

        if (!formData.fullname || !formData.email || !formData.phone || !formData.gender || !formData.password || !formData.username) {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "All fields are required",

                    }
                ))
            return;
        }

        if (!emailRegex.test(formData.email)) {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Invalid email!",

                    }
                ))
            return;
        }
        if (formData.phone && !phoneRegex.test(formData.phone)) {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Invalid mobile!",

                    }
                ))
            return;
        }
       

        setLoading(true)
        customRequest(apiPath.SignUpWIthEmail, formData)
            .then(res => {
             //   console.log(res)
                dispatch(
                    setShowAlertPopup(
                        {
                            show: true,
                            title: "Alert",
                            message: res.message,
                            showLoginBtn: true
                        }
                    ))
                    navigate("/")

            })
            .catch(e => {
               // console.log(e)
                dispatch(
                    setShowAlertPopup(
                        {
                            show: true,
                            title: "Alert",
                            message: e.message,
                        }
                    ))

            })
            .finally(() => setLoading(false))

    }

    
  const continueToGoogleSignup = (user) => {
    if (!!user) {
      navigate('/register', { state: { email: user.email, google_id: user.sub, full_name: user.name, email_verified: user.email_verified, picture: user.picture } })
    } else {
        dispatch(
            setShowAlertPopup(
              {
                show: true,
                title: "Alert",
                message: "Something went wrong",
              }
            ))
    }
  }








  const handleGoogleAuth = useGoogleLogin({

    onSuccess: async tokenResponse => {
      // console.log(tokenResponse);
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios
        .get(apiPath.googleAuth, {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then(res => res.data).catch(e => {
          dispatch(
            setShowAlertPopup(
              {
                show: true,
                title: "Alert",
                message: "Something went wrong",
              }
            ))
        }
        )
      if (userInfo) {
        continueToGoogleSignup(userInfo)
      }
    },
  })

    React.useEffect(() => {
        getLanguages();
    }, [])

    return {setIsAgreeWithTerms,isAgreeWithTerms, handleCreateAccount, getLanguages, formData, handleSelectGender, handleInput, handleSelectLanguage,handleGoogleAuth, languages, loading };
}

export default useCreateAccount

