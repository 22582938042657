import moment from "moment";
import React from "react";
import { LivePostCard } from "../livePostCard";
import Loader from "../Loader";
import { PostCard } from "../postCard";

const Posts = ({ post, loading, isLiveFeed = false }) => {
  if (loading) {
    return <Loader />;
  }

  if (post?.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "70vh",
          flex: 1,
        }}
      >
        <img
          src={require("../../assets/empty_post.png")}
          style={{ height: 240, width: 240, objectFit: "contain" }}
        />
        <p
          style={{ fontFamily: "Open Sans", fontSize: 15, textAlign: "center" }}
        >
          Nothing found.
        </p>
      </div>
    );
  }

  return (
    Array.isArray(post) &&
    post?.map((post) =>
    // console.log(moment(post?.start_date).calendar(), moment(post?.end_date).format("LT")),
      isLiveFeed ? (
        <LivePostCard key={post?.id} post={post} />
      ) : (
        post?.is_live_feed === "yes" && moment(moment()).isBetween(post?.start_date, post?.end_date) ?
          <LivePostCard key={post?.id} post={post} /> :
          post?.is_live_feed !== "yes" ?
            <PostCard key={post?.id} post={post} /> :
            null
      )
    )
  );
};

export default Posts;
