import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { customRequest } from "../../functions/request";
import { MdTranslate, MdChevronRight } from "react-icons/md"
import axios from "axios";
import { SUBDOMAIN_ID } from "../../constants/subdomain";
import { rootlink } from "../../functions/link";
import { setShowAlertPopup } from "../../redux/dataSlice";
import { useDispatch } from "react-redux";
import { handleErrors } from "../../functions/helper";
import CustomButton from "../../components/CustomButton";

export default function LanguageSelectionScreen() {
    let navigate = useNavigate();
    const [languages, setLanguages] = React.useState(null);
    const [selectedLanguageId, setSelectedLanguageId] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);

    const dispatch= useDispatch()

  

    const handleRegister = async (data) => {
        const url = `${rootlink}/auth/register-with-google`;
        try {
          const resp = await axios.post(url, data, {
            headers: {
              Accept: 'application/json',
              subdomain: SUBDOMAIN_ID,
            },
          });
          if (resp.data.status === 'success') {
            dispatch(
                setShowAlertPopup({
                    show: true,
                    title: "Alert",
                    message:resp.data.message,
                  //  showLoginBtn: true
                })
            )
            // localStorage.setItem('user', JSON.stringify(resp.data.user));
            // localStorage.setItem('token', resp.data.token);
             navigate('/');
          } 
        } catch (error) {
         // alert('Some error occurred');
          handleErrors(error)
        }
      };
      

    const handleGetStarted = async() => {
        setIsLoading(true)

        let userDetail = localStorage.getItem('register_user_data')
        userDetail = JSON.parse(userDetail);
        let registrationData = {
            language_id: selectedLanguageId,
            ...userDetail
        }
        try {
            await handleRegister(registrationData)
        } catch (error) {
            dispatch(
                setShowAlertPopup({
                    show: true,
                    title: "Alert",
                    message:error.message,
                    showLoginBtn: true
                })
            )
        }
        finally{
            setIsLoading(false)

        }

    };

    const handleLanguageSelect = (e) => {
        setSelectedLanguageId(e.target.value)
    }

    const getLanguages = () => {
        customRequest('auth/subdomain-languages').then((res) => {
            setSelectedLanguageId(res.languages[0].id||1);
            setLanguages(
                res.languages
            )
        })
    }

    React.useEffect(() => {
        getLanguages();
    }, [])

    // const handleGetStarted = () => {
    //     navigate("/locationSelectionScreen");
    // };

    return (
        <div style={{ height: "100vh", display: "flex", flexDirection: "column", zIndex: 1 }}>
            {/* <img src={require("../../assets/login-back.png")} style={{ position: "absolute", zIndex: 1, mixBlendMode: "soft-light" }} /> */}
            <img src={require("../../assets/main_logo.png")} style={{ height: "40vh", width: "100%", zIndex: 2, objectFit: "contain" }} />

            {/* <img src={require("../../assets/logo.png")} style={{ position: "absolute", top: "20%", left: "50%", transform: "translateX(-50%)", zIndex: 2, height: "36px" }} /> */}
            <div style={{ height: "54%", backgroundColor: "white", boxShadow: "0 -4px 10px rgba(0,0,0,0.1)", borderTopRightRadius: 20, borderTopLeftRadius: 20, marginTop: "auto", zIndex: 2 }}>
            <div className="px-3 py-4" style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px', margin: '0 auto' }}>
                    <h4 style={{ fontSize: 19 }}>
                        <b style={{ fontFamily: "Open Sans" }}><span style={{ color: "orangered" }}>Which languages</span> do you speak or know ?</b><br />
                    </h4>
                    <span style={{ fontSize: 12, fontFamily: "Open Sans", color: "gray" }}>Get local updates based on languages you know</span>

                    <div >
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
                            <label htmlFor="phone" style={{ fontSize: 12, color: "gray", marginTop: 24, fontFamily: "Open Sans", lineHeight: "1px" }}>Your Preferred Languages</label>
                            <label htmlFor="phone" style={{ fontSize: 12, color: "gray", marginTop: 24, fontFamily: "Open Sans", lineHeight: "1px" }}>(Optional)</label>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", borderRadius: 8, backgroundColor: "rgba(0,0,0,0.1)", border: "none", padding: "10px 8px", fontFamily: "Open Sans", width: "100%" }}>
                            <MdTranslate />
                            {
                                languages === null || languages.length == 0
                                    ?
                                    <span style={{ fontSize: 12, marginLeft: 12 }}>English (default)</span>
                                    :
                                    <select onChange={(e) => { handleLanguageSelect(e) }} style={{ flex: 1, paddingLeft: 20, backgroundColor: "transparent", border: "none" }}>
                                        {
                                            languages.map((lang, index) => {
                                                return (
                                                    <option key={index} value={lang.id}>{lang.language_name}</option>
                                                )
                                            })
                                        }
                                    </select>

                            }
                            {/* <MdChevronRight style={{ marginLeft: "auto" }} /> */}
                        </div>
                    </div>
                    <CustomButton disabled={isLoading ? true : false} className={"btn-block mt-4"} label={"Get Started"} onClick={handleGetStarted} />

                    {/* <Link to="/register" className="text-center mt-2">New User ? Register Here</Link> */}
                </div>
            </div>
        </div>
    );
}
