import React from 'react';
import { useNavigate } from 'react-router-dom';
import { customRequest } from '../../functions/request';
import { MdLocationPin, MdPlayCircle } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setShowAlertPopup, setShowHidePostStatusPopup } from '../../redux/dataSlice'
import { RWebShare } from 'react-web-share';
import { SUBDOMAIN_NAME } from '../../constants/subdomain';
import { isImageUrl } from '../../functions/helper';
import LazyLoadImage from '../../components/lazyLoadImage';
import Loader from '../../components/Loader';
import { postShareLink } from '../../functions/link';

export function LivePostCard(props) {
    let [upvoteCount, setUpvoteCount] = React.useState(props.post.upvotes);
    let [downvoteCount, setDownVoteCount] = React.useState(props.post.downvotes);
    let navigate = useNavigate();
    let [pinned, setPinned] = React.useState(false);
    const [buttonColor, setButtonColor] = React.useState(props.post.badge_color);
    const dispatch = useDispatch();
    const videoRef = React.createRef();
    const videoPlayerRef = React.createRef();






    const handlePostVote = (vote) => {

        if (!localStorage.getItem('token')) {
            dispatch(
                setShowAlertPopup({
                    show: true,
                    title: "Alert",
                    message: "Login to upvote or downvote.",
                    showLoginBtn: true
                })
            );
            return;
        }

        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        customRequest('post/votepost', { vote: vote, post_id: props.post.id }).then((res) => {
            if (res.status == "incvote" && res.type == "upvote") {
                setUpvoteCount((upvote) => {
                    return upvote + 1;
                })
            } else if (res.status == "incvote" && res.type == "downvote") {
                setDownVoteCount(downvoteCount + 1)
            } else if (res.status == "decvote" && res.type == "upvote") {
                setUpvoteCount(upvoteCount - 1)
            } else if (res.status == "decvote" && res.type == "downvote") {
                setDownVoteCount(downvoteCount - 1)
            } else if (res.status == "decinc" && res.type == "downvote") {
                setDownVoteCount(downvoteCount - 1)
                setUpvoteCount(upvoteCount + 1)
            } else if (res.status == "decinc" && res.type == "upvote") {
                setUpvoteCount(upvoteCount - 1)
                setDownVoteCount(downvoteCount + 1)
            }

        })
    }


    const handleDeletePost = () => {
        customRequest('post/delete', { postid: props.post.id }).then((res) => {
            document.querySelector('#post-' + props.post.id).style.display = "none";
        })
    }

    const handleSharePost = async () => {
        await customRequest('post/new-share', { "post_id": props.post.id });
        // window.navigator.share({
        //     title: "Share Localbol Post",
        //     text: props.post.contents[0].text,
        //     url: postShareLink + props.post.slug
        // })
    }




    const handlePinPost = () => {
        customRequest("post/pinpost", { user_id: props.post.user.id, post_id: props.post.id, pin: !pinned }).then((res) => {
            if (res.status == "success") {
                setPinned(!pinned);
                // alert('Post pinned successfully');
            }
        })
    }


    const handleButtonColor = () => {
        if ((upvoteCount + downvoteCount) > 10 && upvoteCount > (upvoteCount + downvoteCount) * 0.5) setButtonColor('green')
        else if ((upvoteCount + downvoteCount) > 10 && downvoteCount > (upvoteCount + downvoteCount) * 0.5) setButtonColor('red')
        else if ((upvoteCount + downvoteCount) > 0) setButtonColor('yellow')
        else setButtonColor('gray')
    }

    const handlePlayVideo = (e) => {
        e.stopPropagation();
        if (videoRef.current !== null) {
            if (videoRef.current.paused) {
                videoPlayerRef.current.style.display = "none";
                videoRef.current.play();
                videoRef.current.setAttribute('controls', 'controls');
            } else {
                videoRef.current.pause();
                videoPlayerRef.current.style.display = "flex";
            }
        }
    }

    const handleOnVideoPlay = (e) => {
        videoPlayerRef.current.style.display = "none";
    }

    const handlePauseVideo = () => {
        videoPlayerRef.current.style.display = "flex";

        if (videoRef.current !== null) {
            if (!videoRef.current.paused) {
                videoPlayerRef.current.style.display = "flex";
                videoRef.current.pause();
            }
        }
    }



    React.useEffect(() => {
        handleButtonColor();
    }, [upvoteCount, downvoteCount])





    return (
        <div className="postcardmain" id={"post-" + props.post.id}>
         
            <div className="postcard-img" onClick={() => { navigate('/post/' + props?.post?.slug) }}>
                {
                    props?.post?.media === null || props?.post?.media[0] == undefined
                        ?
                        props?.post?.contents[0]?.description && props?.post?.contents[0]?.description?.includes('https://yout')
                            ?
                            props?.post?.contents[0]?.description?.match(/\bhttps?:\/\/\S+/gi)[0]?.includes('be/')

                                ?
                                <iframe width="100%" className="post-media-content" src={"https://www.youtube.com/embed/" + props?.post?.contents[0]?.description?.match(/\bhttps?:\/\/\S+/gi)[0]?.split('e/')[1] } frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{ height: "70vh" }} ></iframe>
                                :
                                <iframe width="100%" className="post-media-content" src={"https://www.youtube.com/embed/" + props?.post?.contents[0]?.description?.match(/\bhttps?:\/\/\S+/gi)[0]?.split("=")[1] } frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{ height: "70vh" }} ></iframe>

                            :
                            null
                        :
                        props?.post?.media[0]?.type == "video"
                            ?
                            // <video controls="false" className="img-fluid" poster={require("../assets/common_thumbnail.jpg")}>
                            //     <source src={imageRootlink + "/post/" + props.post.media[0].path} type="video/mp4"></source>
                            // </video>
                            <div style={{ position: "relative" }}>
                                <div ref={videoPlayerRef} onClick={(e) => { handlePlayVideo(e) }} style={{ position: "absolute", backgroundColor: "white", height: 60, width: 60, borderRadius: 60, top: "50%", left: "50%", transform: "translate(-50%,-50%)", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <MdPlayCircle size={64} color="orangered" />
                                </div>
                                <video onPause={handlePauseVideo} onPlay={handleOnVideoPlay} controls onClick={() => { handlePauseVideo() }} className="img-fluid video-ele post-media-content" preload="metadata" ref={videoRef} style={{ width: "100%", objectFit: "cover", height: "70vh" }}>
                                    <source src={props?.post?.media[0]?.path + "#t=0.6" } type="video/mp4"></source>
                                </video>
                            </div>
                            :
                            props?.post?.media[0]?.type === "audio"
                                ?
                                <audio src={props?.post?.media[0]?.path} controls style={{ borderRadius: 60 }}></audio>
                                :
                                props?.post?.media[0]?.type === "link"
                                    ?
                                    isImageUrl(props?.post?.media[0]?.path) ?
                                        <LazyLoadImage
                                            onError={(e) => { e.target.src = 'https://img.freepik.com/free-vector/image-upload-concept-landing-page_23-2148309693.jpg' }}
                                            src={props?.post?.media[0]?.path} className="img-fluid"
                                            style={{ maxHeight: props.isFullHeight ? "auto" : "400px", objectFit: "cover", width: "100%" }}
                                        /> :
                                        props?.post?.media[0]?.path?.toString()?.includes('be/')
                                            ?
                                            <iframe loading='lazy' width="100%" className="post-media-content" src={"https://www.youtube.com/embed/" + props?.post?.media[0]?.path?.split('e/')[1] } frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{ height: "70vh" }} ></iframe>
                                            :
                                            <iframe loading='lazy' width="100%" className="post-media-content" src={"https://www.youtube.com/embed/" + props?.post?.media[0]?.path?.split("=")[1] } frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{ height: "70vh" }}></iframe>
                                    // ?
                                    // <iframe width="100%" height="170" src={"https://www.youtube.com/embed/" + props.post.media[0].path.split('e/')[1]} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                                    // :
                                    // <iframe width="100%" height="170" src={"https://www.youtube.com/embed/" + props.post.media[0].path.split("=")[1]} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                                    :
                                    <LazyLoadImage
                                        onError={(e) => { e.target.src = 'https://img.freepik.com/free-vector/image-upload-concept-landing-page_23-2148309693.jpg' }}
                                        src={props?.post?.media[0]?.path} className="img-fluid"
                                        style={{ maxHeight: props.isFullHeight ? "auto" : "400px", objectFit: "cover", width: "100%" }}
                                    />
                }
                {/* <div className="post-view-btn">
                    <i className="fal fa-eye"></i> 1200 Views
                </div> */}
            </div>
            <div className="postcard-header">
            
            <div className="d-flex flex-column bd-highlight">
                <div className="bd-highlight" onClick={() => { navigate('/post/' + props.post.slug) }}>
                    <span style={{ fontWeight: "600", fontSize: 15 }}>
                        {props?.post?.contents[0]?.title}
                    </span>
                    {
                        props?.post?.contest_hashtag
                            ?
                            <div style={{ display: "table", padding: "2px 8px", backgroundColor: "rgba(0,0,0,0.1)", fontSize: 12, marginRight: "auto", borderRadius: 60 }}>
                                {
                                    props?.post?.contest_hashtag?.toString()?.split(",")?.map((tag, index) => {
                                        return (
                                            <button key={index} style={{ marginRight: "auto", border: "none" }}>
                                                #{tag}
                                            </button>

                                        )
                                    })

                                }
                            </div>
                            :
                            null
                    }
                    {
                        props?.post?.contents[0]?.description
                            ?
                            <span style={{ display: "block", fontSize: 15 }}>
                                {props.post.contents[0].description}
                            </span>
                            :
                            null
                    }
                </div>
            </div>
            </div>
            <div className="postcard-footer">
                <div className="d-flex flex-column bd-highlight">
                    {!!props?.post?.location?.locationMaster?.location_name && isNaN(props?.post?.location?.locationMaster?.location_name) &&
                        <div className="py-1 bd-highlight">
                            <div className="d-flex flex-row bd-highlight align-items-center">
                                <div className="bd-highlight me-2" style={{ display: "flex", alignItems: "center" }}>
                                    <MdLocationPin />
                                    {/* <i className="fal fa-location-pin"></i> */}
                                </div>

                                <div className="bd-highlight flex-grow-1">
                                    {

                                        <h6>Posted In : {props.post?.location?.locationMaster?.location_name}</h6>

                                    }
                                    {/* <h6>Posted In : {props.post === null ? "" : props.post.location.location}</h6> */}

                                </div>
                                <div className="bd-highlight">
                                    <button onClick={() => { dispatch(setShowHidePostStatusPopup(true)) }} type="button" style={{ height: 'auto', width: 40, border: 'none', borderRadius: 60, backgroundColor: buttonColor, textTransform: "capitalize" }}>
                                        {/* {props.post.status} */}
                                        <i className="fal fa-info-circle" style={{ fontSize: 14 }}></i>
                                    </button>
                                </div>
                            </div>
                        </div>}
                    <div className="py-1 bd-highlight border-bottom" style={{ display: "flex", overflowX: "auto", width: "100%", height: 40 }}>
                        <button type="button" className="btn btn-dark" onClick={() => { handlePostVote("upvote") }} style={{ whiteSpace: "nowrap" }}>
                            <i className="fal fa-thumbs-up fa-2xx mx-2"></i>
                            <span>{upvoteCount}</span>
                        </button>
                        <button type="button" className="btn btn-dark" onClick={() => { handlePostVote("downvote") }} style={{ whiteSpace: "nowrap" }}>
                            <i className="fal fa-thumbs-down fa-2xx mx-2"></i>
                            <span>{downvoteCount}</span>
                        </button>
                        <button type="button" className="btn btn-dark" onClick={() => { navigate('/post/' + props.post.slug) }} style={{ whiteSpace: "nowrap", }}>
                            <i className="fal fa-comment fa-2xx"></i> {props.post.comments.length}
                        </button>
                        <RWebShare
                            data={{
                                text: props?.post?.contents[0]?.text,
                                url: postShareLink + props.post.slug,
                                title: `Share ${SUBDOMAIN_NAME} Post`,
                            }}
                            onClick={handleSharePost}
                        >
                            <button type="button" className="btn btn-dark d-flex px-1">
                                <i className="far fa-sign-out gly-rotate-90 fa-2xx me-2 "></i>
                                Share
                            </button>
                        </RWebShare>

                        {
                            props?.post?.source?.toLowerCase() === "contest"
                                ?
                                <button style={{ whiteSpace: "nowrap" }} type="button" className="btn btn-dark" onClick={() => { navigate('/contest', { state: { contest_id: props.post.contest_id } }) }}>
                                    Source : {props?.post?.source}
                                </button>
                                :
                                props?.post?.source?.toLowerCase() === "web"
                                    ?
                                    <a type="button" href={props.post.source_link} className="btn btn-dark" target="_blank" style={{ whiteSpace: "nowrap" }}>
                                        Source : {props?.post?.source}
                                    </a>
                                    :
                                    <button type="button" className="btn btn-dark" style={{ whiteSpace: "nowrap" }}>
                                        Source : {props?.post?.source}
                                    </button>

                        }
                        <button type="button" className="btn btn-dark" style={{ whiteSpace: "nowrap" }}>
                            {props?.post?.mood == null ? "" : props?.post?.mood?.mood_name}
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}


