import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { customMultipartRequest, customRequest, customRequestGET } from "../functions/request";
import { MdPinDrop, MdImage, MdMusicVideo, MdAudiotrack, MdLink, MdPoll, MdChevronLeft, MdMyLocation } from "react-icons/md";
import { imageRootlink } from "../functions/link";
import { useDispatch, useSelector } from "react-redux";
import { setShowAlertPopup } from "../redux/dataSlice";
import { handleErrors, isValidURL } from "../functions/helper";
import { Colors } from "../constants/colors";

export default function NewPostScreen() {
    let navigate = useNavigate();
    let [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user')));
    const sliceSelectedLanaguageId = useSelector((state) => state.data.selectedLanguageId);

    const locationref = useLocation();
    let [languages, setLanguages] = React.useState(null);
    let [location, setLocation] = React.useState('');
    let [selectedLanguage, setSelectedLanguage] = React.useState(sliceSelectedLanaguageId);
    let [selectedMediaType, setSelectedMediaType] = React.useState('text');
    let [media, setMedia] = React.useState(null);
    let [title, setTitle] = React.useState('');
    let [description, setDescription] = React.useState('');
    let [moods, setMoods] = React.useState(null);
    let [selectedMood, setSelectedMood] = React.useState(null);
    let [selectedSource, setSelectedSource] = React.useState('By Me');
    let [postSourceLink, setPostSourceLink] = React.useState('');
    let [isMediaLinkSelected, setIsMediaLinkSelected] = React.useState(false);
    const [imageError, setImageError] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const dispatch = useDispatch();


    const handleCompleteTask = () => {
        customRequest('contest/contest-day-task', {
            contest_id: locationref.state.contest_id, contest_day_id: locationref.state.day_id, "status": "Done",
            "action": locationref.state.action
        }).then(res => {
            navigate(-1)

        }).catch(e =>
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Something went wrong",
                    }
                )
            )
        )

    }


    const handleImageError = () => {
        //  console.log('error in image')
        setImageError(true)

    }
    const handleInput = (e) => {
        if (e.target.name == "title") {
            setTitle(e.target.value);
        } else if (e.target.name == "description") {
            setDescription(e.target.value);
        } else if (e.target.name == "media") {
            let element;
            if (selectedMediaType === "image") {
                element = document.createElement('img');
            } else if (selectedMediaType === "video") {
                element = document.createElement('video');
                element.setAttribute('controls', 'true');
                // element.setAttribute('')
            }


            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {
                element.src = reader.result;
                element.setAttribute('style', "width:calc(100vw - 40px);height:160px;object-fit:cover;border-radius:20px;")
                //  console.log(reader.result);
                document.getElementById('mediadisplay').innerHTML = '';
                document.getElementById('mediadisplay').append(element);
            }
            setMedia(
                e.target.files[0]
            )
        }
    };

    const handleGetLanguage = async () => {
        customRequest('post/languages').then((res) => {
            setLanguages(
                res.languages.map((lang) => {
                    return (
                        <div key={lang.id} onClick={() => { setSelectedLanguage(lang.id) }} style={{ border: selectedLanguage == lang.id ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedLanguage == lang.id ? "orangered" : "gray", backgroundColor: selectedLanguage == lang.id ? "rgba(255, 144, 25,0.1)" : "white", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                            {lang.language_name}
                        </div>
                    )
                })
            )
        })

        customRequest('post/moods', { language_id: selectedLanguage }).then((res) => {
            setMoods(res.moods)
            setSelectedMood(res.moods[0].id)
            // res.moods.map((mood,index) => {
            //     return (
            //         <div  key={mood.id} onClick={() => { setSelectedMood(mood.id) }} style={{marginBottom:10, border: selectedMood == mood.id ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMood == mood.id ? "orangered" : "gray", backgroundColor: selectedMood == mood.id ? "rgba(255, 144, 25,0.1)" : "white", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
            //             {mood?.mood_name}
            //         </div>
            //     )
            // })
            // )
        })

    }

    const handleCreatePost = () => {
        if (location === undefined || location === null || location === "") {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Please select location to post.",
                    }
                )
            )
            return;
        }

        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        if (selectedMediaType === 'text' && (!title || !description)) {
            dispatch(
                setShowAlertPopup({
                    show: true,
                    title: 'Alert',
                    message: 'Please enter post title or descriptions.',
                })
            );
            return;
        }

        if (isMediaLinkSelected && !media) {
            dispatch(
                setShowAlertPopup({
                    show: true,
                    title: 'Alert',
                    message: 'Please provide the link.',
                })
            );
            return;
        }

        if (isMediaLinkSelected && !isValidURL(media)) {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Please provide the valid link.",
                    }
                )
            )
            return;
        }


        let formdata = new FormData();

        formdata.append('location', location);
        formdata.append('language_id', selectedLanguage);
        formdata.append('title', title);
        formdata.append('description', description)
        formdata.append('source', selectedSource);
        formdata.append('mood_id', selectedMood);
        formdata.append('visible', 0);
        formdata.append('media', media);
        formdata.append('media_type', selectedMediaType);
        formdata.append('user_id', user?.id);
        formdata.append('source_link', postSourceLink);

        if (locationref?.state?.contest_id) {
            formdata.append('contest_id', locationref.state.contest_id);

        }

        customMultipartRequest('post/newpost', formdata).then((res) => {
            if (res.message == 'Given data was invalid') {
                dispatch(
                    setShowAlertPopup(
                        {
                            show: true,
                            title: "Alert",
                            message: "Given data was invalid.",
                            showLoginBtn: true
                        }
                    )
                )
            } else {
                if (locationref?.state?.contest_id) {
                    handleCompleteTask()
                } else {
                    dispatch(
                        setShowAlertPopup(
                            {
                                show: true,
                                title: "Alert",
                                message: "Post created successfully.",
                            }
                        )
                    )
                    navigate('/home', { replace: true });
                    window.location.reload();
                }
            }
        }).catch(e => handleErrors(e))
    };

    // const handleCurrentLocation = () => {
    //     console.log('run')

    //     try {
    //         navigator.geolocation.getCurrentPosition(position => {
    //             console.log(position)
    //             setLocation("current:" + position.coords.latitude + "," + position.coords.longitude)
    //        }); 
    //     } catch (error) {
    //         console.log(error)
    //         dispatch(
    //             setShowAlertPopup({
    //                 show: true,
    //                 title: 'Alert',
    //                 message: 'Unable to get location right now.',
    //             })
    //         );
    //     }

    // }

    const handleCurrentLocation = () => {
        setLoading(true)
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLoading(false)
                setLocation("current:" + position.coords.latitude + "," + position.coords.longitude);
            },
            (err) => {
                setLoading(false)
                dispatch(
                    setShowAlertPopup({
                        show: true,
                        title: 'Alert',
                        message: err.message,
                    })
                );
            }
        )


    };



    React.useEffect(() => {
        handleGetLanguage();

        if (locationref.state?.source) {
            setSelectedSource(locationref.state?.source)

        }
    }, [ selectedLanguage])

    //console.log(selectedMediaType)

    return (
        <div className="main_warp" style={{backgroundColor:Colors.white }}>
            <div className="np__header" style={{ position: "relative", justifyContent: "space-between" }}>
                <MdChevronLeft style={{ fontSize: 32, cursor: "pointer" }} onClick={() => { navigate(-1) }} />
                <span style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", fontFamily: "Open Sans", fontWeight: "bold" }}>Create Post</span>
                <button style={{ border: "none", padding: "4px 16px", fontSize: 16, fontFamily: "Open Sans", borderRadius: 60, background: "orangered", color: "white" }} onClick={() => { handleCreatePost() }}>Post</button>
            </div>

            <div style={{ display: "flex", alignItems: "center", padding: "0 12px", marginTop: 12 }}>
                {
                    user && user.user_detail && user.user_detail.profile_pic
                        ?
                        <img onError={handleImageError}
                            src={imageError ? `${process.env.PUBLIC_URL}/profile_image.png` : imageRootlink + "/profilepic/" + user.user_detail.profile_pic} className="img-fluid" style={{ width: "58px", height: "58px", borderRadius: 48 }} />
                        :
                        <img
                            // onError={handleImageError}
                            alt='user_image'
                            src={`${process.env.PUBLIC_URL}/profile_image.png`}
                            className="img-fluid" style={{ width: "58px", height: "58px", borderRadius: 48 }}
                        />
                }
                {/* <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" style={{ width: "46px", height: "46px", borderRadius: 40 }} /> */}
                <div style={{ margin: "10px 12px" }}>
                    <h5 style={{ fontSize: "15px", lineHeight: "1px", fontFamily: "Open Sans" }}>{user?.full_name}</h5>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span style={{ fontSize: 12, fontFamily: "Open Sans", color: "black", marginLeft: 4 }}>Select Location</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", marginLeft: "12px", cursor: "pointer" }} onClick={() => { navigate('/locationSearchScreen', { state: { type: "search" } }) }}>
                            <MdPinDrop color="orangered" size={12} />
                            <span style={{ fontSize: 12, fontFamily: "Open Sans", color: "orangered", marginLeft: 4 }}>Add Location</span>
                        </div>
                    </div>
                    <div style={{ width: "calc(100vw - 86px)",display: "flex", flexWrap: "wrap" }}>
                        {loading ?
                            <div className="spinner-border text-secondary" role="status" style={{ width: '25px', height: '25px', marginLeft: '10px' }} >
                            </div>
                            : <div className="mt-1" onClick={handleCurrentLocation} style={{height:25, border: location.includes('current:') ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, marginRight: 2, alignItems: "center", justifyContent: "center", fontSize: 10, color: location.includes('current:') ? "orangered" : "gray", backgroundColor: location.includes('current:') ? "rgba(255, 144, 25,0.1)" : "white", whiteSpace: "nowrap", cursor: "pointer" }}>
                                <MdMyLocation size={14} /> <span>My Location</span>
                             </div>}
                           {
                            user?.user_detail?.current_city
                                ?
                                <div  onClick={() => { setLocation(user?.user_detail?.current_city) }} style={{ border: location === user.user_detail.current_city ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 10, color: location == user.user_detail.current_city ? "orangered" : "gray", backgroundColor: location == user.user_detail.current_city ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", cursor: "pointer" }}>
                                    {user.user_detail.current_city}
                                </div>
                                :
                                null
                        }

                        {
                            user?.user_detail?.other_cities
                                ?
                                user.user_detail.other_cities.split(',')?.filter(city => city !== 'null' && city !== 'undefined'  && city!=="")?.map(city => {
                                    if (city != undefined || city != "") {
                                        return (
                                            city !== 'null' && city !== 'undefined' && <div className="mt-1" onClick={() => { setLocation(city) }} key={Math.random()} style={{ border: location == city ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, marginLeft: 2, alignItems: "center", justifyContent: "center", fontSize: 10, color: location == city ? "orangered" : "gray", backgroundColor: location == city ? "rgba(255, 144, 25,0.1)" : "white", whiteSpace: "nowrap", cursor: "pointer" }}>
                                                {city}
                                            </div>
                                        )
                                    }
                                })
                                :
                                null
                        }
                      

                    </div>
                </div>
            </div>

            <div style={{ padding: "10px 12px" }}>
                <input type="text" name="title" onChange={handleInput} placeholder="Add title" style={{ border: "none", fontFamily: "Open Sans", fontSize: 20, fontWeight: 500 }} />
                <textarea name="description" rows={4} onChange={handleInput} placeholder="What’s on your mind?" style={{ border: "none", fontFamily: "Open Sans", fontSize: 16, width: "100%", marginTop: 12 }}></textarea>
            </div>

            <div id="mediadisplay" style={{ padding: 20, maxWidth: '100%', height: 'auto' }}></div>


            <h5 style={{ padding: "0 12px", fontSize: 12, lineHeight: "1px", marginTop: 16, marginBottom: 12 }}>Select Media</h5>
            <input type="file" onChange={handleInput} style={{ display: "none" }} id="selectMedia" name="media" />

            <div style={{ display: "flex", alignItems: "center", overflowX: "auto" }}>
                <label onClick={() => { setSelectedMediaType('text'); setIsMediaLinkSelected(false); }} style={{ border: selectedMediaType === "text" ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType === "text" ? "orangered" : "gray", backgroundColor: "white", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Text
                </label>
                <label htmlFor="selectMedia" onClick={() => { setSelectedMediaType('image'); setIsMediaLinkSelected(false); }} style={{ border: selectedMediaType === "image" ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType === "image" ? "orangered" : "gray", backgroundColor: "white", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Picture
                </label>
                <label htmlFor="selectMedia" onClick={() => { setSelectedMediaType('audio'); setIsMediaLinkSelected(false); }} style={{ border: selectedMediaType === "audio" ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType === "audio" ? "orangered" : "gray", backgroundColor: "white", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Audio
                </label>
                <label htmlFor="selectMedia" onClick={() => { setSelectedMediaType('video'); setIsMediaLinkSelected(false); }} style={{ border: selectedMediaType === "video" ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType === "video" ? "orangered" : "gray", backgroundColor: "white", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Video
                </label>
                <label onClick={() => { setSelectedMediaType('link'); setIsMediaLinkSelected(true); }} style={{ border: selectedMediaType === "link" ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType === "link" ? "orangered" : "gray", backgroundColor: "white", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Link
                </label>
            </div>

            {
                isMediaLinkSelected
                    ?
                    <input onChange={(t) => { setMedia(t.target.value) }} style={{ backgroundColor: "rgba(0,0,0,0.03)", border: "1px solid gray", padding: "6px 10px", borderRadius: 10, margin: "10px 10px" }} placeholder="Enter Media Link" />
                    :
                    null
            }


            <h5 style={{ padding: "0 12px", fontSize: 12, lineHeight: "1px", marginTop: 12, marginBottom: 12 }}>Select Language</h5>
            <div style={{ display: "flex", alignItems: "center", overflowX: "auto" }}>
                {
                    languages
                }
            </div>

            {locationref?.state?.source === 'contest' ? null : <>  <h5 style={{ padding: "0 12px", fontSize: 12, lineHeight: "1px", marginTop: 16, marginBottom: 12 }}>Select Source</h5>
                <div style={{ display: "flex", alignItems: "center", overflowX: "auto" }}>
                    <div onClick={() => { setSelectedSource("By Me") }} style={{ border: selectedSource == "By Me" ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedSource == "By Me" ? "orangered" : "gray", backgroundColor: selectedSource == "By Me" ? "rgba(255, 144, 25,0.1)" : "white", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                        By Me
                    </div>
                    <div onClick={() => { setSelectedSource("Forwarded") }} style={{ border: selectedSource == "Forwarded" ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedSource == "Forwarded" ? "orangered" : "gray", backgroundColor: selectedSource == "Forwarded" ? "rgba(255, 144, 25,0.1)" : "white", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                        Forwarded
                    </div>
                    <div onClick={() => { setSelectedSource("web") }} style={{ border: selectedSource == "web" ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedSource == "web" ? "orangered" : "gray", backgroundColor: selectedSource == "web" ? "rgba(255, 144, 25,0.1)" : "white", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                        Web
                    </div>
                </div>
            </>}
            {
                selectedSource == "web"
                    ?
                    <input onChange={(t) => { setPostSourceLink(t.target.value) }} style={{ backgroundColor: "rgba(0,0,0,0.03)", border: "1px solid gray", padding: "6px 10px", borderRadius: 10, margin: "10px 10px" }} placeholder="Souce link" />
                    :
                    null
            }

{
                locationref?.state?.hashTag ? null :
                    <>  <h5 style={{ padding: "0 12px", fontSize: 12, marginTop: 12 }}>Select Bhagwan or Category</h5>
                            <div className="py-1 bd-highlight" style={{ display: "flex",flexWrap:"wrap" }}>
                            {
                                moods?.map((mood) => {
                                    return (
                                        <div className="mt-2" key={mood.id} onClick={() => { setSelectedMood(mood.id) }} style={{  border: selectedMood == mood.id ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMood == mood.id ? "orangered" : "gray", backgroundColor: selectedMood == mood.id ? "rgba(255, 144, 25,0.1)" : "white", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                                            {mood?.mood_name}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
            }
            <div style={{ height: 100 }}></div>
        </div>
    );
}
