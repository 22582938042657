import React from 'react'
import { BottomScrollListener } from 'react-bottom-scroll-listener'
import { MdArrowBack } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useLive } from './hook/useLive'
import Spinner from "react-spinkit";
import LanguageAndMoodModal from '../../components/moodsAndLanguageModal'
import Posts from '../../components/posts'

const Live = () => {
    const navigate = useNavigate()
    const { isLoading, posts,handleGetMorePostOnBottom,isBottomLoading } = useLive()

   // console.log("live",posts)


    return (
        <BottomScrollListener onBottom={handleGetMorePostOnBottom} >
            <div className='main_warp' >
                <div style={{ height: 48, width: "100%", boxShadow: "0 4px 4px rgba(0,0,0,0.1)", marginBottom: 1, display: "flex", alignItems: "center", padding: "0 16px", position: "sticky", top: 0 }}>
                    <MdArrowBack size={24} onClick={() => { navigate('/home') }} cursor="pointer" />
                    <h4 style={{ fontFamily: "Open Sans", fontSize: 20, marginLeft: 10, lineHeight: "1px", marginTop: 5 }}>Live Darshan</h4>
                    <button
                        data-bs-toggle="modal"
                        data-bs-target="#languageModal"
                        type="button"
                        className="btn btn-secondary-light search-hide" style={{ padding: "7px 10px", marginLeft: "auto", marginRight: 10 }}>
                        {/* <i className="fas fa-pin-location fa-lg me-1" style={{ color: '#605e5c', textTransform: "capitalize",marginRight:5 }}></i> */}
                        <div className="bd-highlight col-1 px-0 align-self-center pe-1">
                            <div className="tabs-btn" data-bs-toggle="modal"
                                data-bs-target="#languageModal" >
                                <img alt='localbol' src="assets/images/NavIcons.svg" />
                            </div>
                        </div>
                    </button>
                </div>
                <Posts loading={isLoading} post={posts} isLiveFeed />
                {
                    isBottomLoading
                        ?
                        <div style={{ width: "100%", padding: "16px 0", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Spinner name='wave' />
                        </div>
                        :
                        null
                }
            </div>
            <LanguageAndMoodModal />
        </BottomScrollListener>
    )
}

export default Live