import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { customRequest } from '../../../functions/request';
import { setLivePost, setNewLivePost, setShowAlertPopup } from '../../../redux/dataSlice';


let postOffset = 0
export const useLive = () => {
   const { livePosts: posts, selectedLanaguageId, selectedMood, viewMoods } = useSelector((state) => state.data);
   const [isLoading, setIsLoading] = React.useState(true);
   const currentLocation = useSelector(state => state.data.currentLocation);
   const [isBottomLoading, setIsBottomLoading] = React.useState(false);
   const [allPostLoaded, setAllPostLoaded] = React.useState(false);
   const dispatch = useDispatch()



   const getPosts = (addPosts) => {
      let data = {
         language: selectedLanaguageId,
         is_live_feed:'yes'
      };
      if (addPosts) {
         postOffset = postOffset + 10
         data.offset = postOffset;
      }

      if (selectedMood !== "All") {
         data.mood = selectedMood;
      } else if (viewMoods?.length > 0) {
         data.mood = viewMoods;
      }

      if (currentLocation !== "Everywhere") {
         if (currentLocation === "Nearme") {
            data.location = 'Nearme'
            data.locationCoords = JSON.parse(localStorage.getItem('location_coords'));
         } else {
            data.location = currentLocation;
            data.locationCoords = JSON.parse(localStorage.getItem('location_coords'));

         }
      }

      //console.log('page',data.offset,allPostLoaded)

      if (addPosts && !allPostLoaded) {
         setIsBottomLoading(true);
         customRequest('post/allposts', data).then((res) => {
            setIsBottomLoading(false);
            if (res.posts.length === 0) {
               setAllPostLoaded(true);
            }
            dispatch(setNewLivePost(res.posts));

         }).catch(e => {
         }).finally((() => {
            setIsBottomLoading(false);
            setIsLoading(false)
         }
         )
         )
      } else if (postOffset === 0) {
         setIsLoading(true)
         customRequest('post/allposts', data).then((res) => {
            dispatch(setLivePost(res.posts));
         }).catch(e => {
            dispatch(
               setShowAlertPopup(
                  {
                     show: true,
                     title: "Alert",
                     message: "Some error occurred.",
                  }
               )
            )
         }).finally((() => {
            setIsBottomLoading(false);
            setIsLoading(false)
         }
         )
         )
      }

   }

   const handleGetMorePostOnBottom = () => {
      if (!allPostLoaded) {
         setIsBottomLoading(true);
         getPosts(true);
      }
   }

   React.useEffect(() => {
      postOffset = 0
      setAllPostLoaded(false)
      if (selectedMood && currentLocation ) {
         getPosts();
      }
   }, [selectedMood, currentLocation, selectedLanaguageId])

   return { posts, isLoading, handleGetMorePostOnBottom, isBottomLoading }
}
