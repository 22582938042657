import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "react-spinkit";
import { PostCard } from "../components/postCard";
import { customRequest } from "../functions/request";
import { MdArrowBack, MdSend } from "react-icons/md"
import { defaultUserImageLink } from "../functions/link";
import { useDispatch } from "react-redux";
import { setShowAlertPopup } from "../redux/dataSlice";
import { SUBDOMAIN_NAME } from "../constants/subdomain";
import { Colors } from "../constants/colors";

export default function SinglePostScreen() {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const [post, setPost] = React.useState(null);
    const [postId, setPostId] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [comment, setComment] = React.useState('');
    const [comments, setComments] = React.useState([]);
    

    React.useEffect(() => {
        customRequest('post/singlepost/' + params.postid).then((res) => {
            if (!!res?.post) {
                setPostId(res?.post?.id)
                setComments(res?.post?.comments)
                setPost(
                    <PostCard post={res.post} isFullHeight={true} />
                )
            }           
            setIsLoading(false);
        })
    }, [])

    const handleSendComment = () => {
        if (!localStorage.getItem('token')) {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Login to comment in this post.",
                        showLoginBtn: true
                    }
                )
            );
            return;
        }

        if (comment?.length === 0) {
            alert("Write something...")
            return;
        }

        let user = JSON.parse(localStorage.getItem('user'));

        customRequest('post/newcomment', { post_id: postId, comment: comment }).then((res) => {
            if (res.status === "success") {
                setComment("");
                let prevComments = comments;
                comments.push(
                    {
                        comment: comment,
                        user: {
                            full_name: user.full_name
                        }
                    }
                )
                setComments(prevComments)
                window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
            }
        })
    }

    if (isLoading) {
        return (
            <div style={{height:"100vh",width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                <Spinner name="ball-scale-multiple" />
            </div>
        )
    }

    if (!post) {
        return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "70vh",
                flex: 1,
              }}
            >
              <img
                src={require("../assets/empty_post.png")}
                style={{ height: 240, width: 240, objectFit: "contain" }}
              />
              <p
                style={{ fontFamily: "Open Sans", fontSize: 15, textAlign: "center" }}
              >
                Nothing found.
              </p>
            </div>
          );
    }


    return (
        <div className="main_warp">
            <div style={{ height: "100%", width: "100%", position: "relative" }} >
            <div style={{ height: 48, width: "100%", boxShadow: "0 4px 4px rgba(0,0,0,0.1)", marginBottom: 1, display: "flex", alignItems: "center", padding: "0 16px", position: "sticky", top: 0,backgroundColor:Colors.white }}>
                    <MdArrowBack size={24} onClick={() => { navigate(-1) }} />
                    <h4 style={{ fontFamily: "Open Sans", fontSize: 20, marginLeft: 10, lineHeight: "1px", marginTop: 5 }}>{SUBDOMAIN_NAME}</h4>
                </div>
                {
                    post
                }

                {
                   !!comments.length && comments?.map((item) => {
                        return (
                            <div style={{ display: "flex", alignItems: "center", borderTop: "1px solid rgba(0,0,0,0.1)", padding: "6px 16px" }}>
                                <img src={defaultUserImageLink} style={{ height: 36, width: 36, borderRadius: 36 }} />
                                <div style={{ marginLeft: 8 }}>
                                    <div style={{ fontFamily: "Open Sans", fontSize: 14, fontWeight: "500" }}>{item.user == null ? "" : item.user.full_name ?? ""}</div>
                                    <div style={{ fontFamily: "Open Sans", fontSize: 12 }}>{item.comment}</div>
                                </div>
                            </div>
                        )
                    })
                }

                <pre style={{ minHeight: 42, width: "100%" }}></pre>
                <div style={{ position: "fixed", bottom: 0, left: 0, right: 0, padding: "6px 16px", display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: "white", borderTop: "1px solid rgba(0,0,0,0.1)" }}>
                    <textarea placeholder="Write here..." style={{ fontFamily: "Open Sans", fontSize: 14, flex: 1, padding: "8px 8px", border: "1px solid orangered", borderRadius: "5px" }} rows={1} onChange={(t) => { setComment(t.target.value) }} value={comment}>{comment}</textarea>
                    <div style={{ backgroundColor: "orangered", padding: "6px 8px", marginLeft: 8, cursor: "pointer", borderRadius: 5, alignItems: "center", justifyContent: "center" }} onClick={() => { handleSendComment() }}>
                        <MdSend size={22} style={{ lineHeight: 1, color: "white", marginBottom: "1px" }} />
                    </div>
                </div>
            </div>
        </div>
    )
}