import React from "react";
import { useNavigate } from "react-router-dom";
// import { SUBDOMAIN_ID } from "../../constants/subdomain";
// import { rootlink } from "../../functions/link";
import { customRequest } from "../../functions/request";
import Spinner from "react-spinkit";
import { getLoggedUser, saveUser } from "../../functions/helper";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import axios from "axios";
import { googleAuthUrl, rootlink } from "../../functions/link";
import { SUBDOMAIN_ID } from "../../constants/subdomain";
import { setShowAlertPopup } from "../../redux/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer";
import GoogleButton from "../../components/GoogleButton";
import CustomInput from "../../components/CustomInput";
import { MdEmail, MdLock, MdPassword } from "react-icons/md";
import { Colors } from "../../constants/colors";
import CustomButton from "../../components/CustomButton";
import apiPath from "../../constants/apiPath";




export default function LoginScreen() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch()
  const { redirectToContestId } = useSelector(state => state.data)





  const handleLogin = async (data) => {
    const url = `${rootlink}/auth/login-with-google`
    // console.log(rootlink)
    try {
      const resp = await axios.post(url, data, {
        headers: {
          Accept: 'application/json',
          subdomain: SUBDOMAIN_ID,
        },
      },)
      if (resp.data.status == "success" && resp.data.user) {
        localStorage.setItem('user', JSON.stringify(resp.data?.user));
        localStorage.setItem('token', resp.data?.token);
        if (redirectToContestId) {
          navigate(`/contest/${redirectToContestId}'`)
        } else {
          navigate('/home');

        }
      } else if (resp.data.message === 'Invalid credentials.') {
        dispatch(
          setShowAlertPopup(
            {
              show: true,
              title: "Alert",
              message: resp.data.message,
              registerBtn: true
            }
          ))
      }


    } catch (error) {
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: "Something went wrong",
          }
        ))
      // console.log(error.message)
    }


  }

  const handleGoogleLogin = async (user) => {

    if (!!user) {
      const body = {
        "email": user?.email,
        //  "email": "user?.email@mail.com",

        "google_id": user?.sub

      }
      await handleLogin(body)
    }
  }

  const handleGoogleAuth = useGoogleLogin({

    onSuccess: async tokenResponse => {
      const userInfo = await axios
        .get(apiPath.googleAuth, {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then(res => res.data)
        .catch(e => {
          dispatch(
            setShowAlertPopup(
              {
                show: true,
                title: "Alert",
                message: "Something went wrong",
              }
            ))
        }
        )
       // console.log(userInfo)

      if (userInfo) {
        handleGoogleLogin(userInfo)
      }
    },
  })

  React.useEffect(() => {
    const user = getLoggedUser()
    if (user !== null && user !== undefined) {
      navigate('/home')
      return;
    }
  }, [])












  return (
    <div className="mb-4 pb-4" style={{
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      paddingTop: 10,
      height: '100vh',
      overflow: 'scroll',
    }} >      {/* <img src={require("../../assets/login-back.png")} style={{ position: "absolute", zIndex: 1, mixBlendMode: "soft-light" }} /> */}
      <div className='p-4 m-4'>
        <img alt="logo" src={require("../../assets/main_logo.png")} style={{ height: "25vh", width: "100%", zIndex: 2, objectFit: "contain", }} />
      </div>
      <span onClick={() => { navigate('/home') }} style={{ color: "black", position: "absolute", top: 20, right: 20, fontSize: 14, fontFamily: "Open Sans, sans-serif", zIndex: 2, cursor: "pointer" }}>Sign up Later</span>
      <div style={{ height: "56%", backgroundColor: "white", boxShadow: "0 -4px 10px rgba(0,0,0,0.1)", paddingTop: "18px", borderTopRightRadius: 20, borderTopLeftRadius: 20, marginTop: "auto", zIndex: 2 }}>
        <div className="px-3 py-4">

          <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px', margin: '0 auto' }}>
            <h5 style={{ lineHeight: 1, fontFamily: "Open Sans" }}><b style={{ color: "orangered" }}>Welcome</b> <b>Back</b></h5>
            <span style={{ fontSize: 14, fontFamily: "Open Sans" }}>Enter your login credentials entered at time of account creation</span>

            <div className="mt-4">

              <GoogleButton onClick={handleGoogleAuth} label={"Sign in with"} />
              <CustomButton className={"btn-block"} label={"Sign in with Email"} onClick={() => navigate("/login")} />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <hr style={{ flex: 2 }} />
                <p style={{ color: Colors.grey, flex: 0.5, textAlign: 'center', marginTop: 10 }}>OR</p>
                <hr style={{ flex: 2 }} />
              </div>
              <CustomButton className={"btn-block"} onClick={() => navigate('/create-account')} label={"Create New Account"} color={Colors.purple500} />
            </div>

          </div>
          </div>

        </div>
        <Footer />
      </div>
      );
}
